.list-group-item {
  font-size: 28px;
}
img.themeImage {
  margin: 10px;
}

.language {
  float: right;
}
.active {

}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: #00a651;
  border-color: #00a651;
}

.btn-primary {
    background-color: grey;
    border-color: grey;
}
