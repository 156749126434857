.step_selector button {
  width: 100%;
  height: 50px;
  margin: 20px 0 20px 0;
  background: #00a651;
  border: #00a651;
}

.step_selector button.btn :hover {
  border: 3px#000000;
  color: #333;
}

.alert-primary {
  font-size: 30px;
}

.alert-warning {
  font-size: 30px;
}

.modal-title {
  font-size: 34px;
}

.modal-body {
  font-size: 30px;
}

h2 {
  margin: 20px 0 20px 0;
  font-size: 48px;
}

html, body {
  background-color: #333;
  color: white;
}

.modal-content {
  background-color: #333;
}

.hint p, .hint {
  font-size: 14px;
  color: #666;
}
.dependsWarning p{
  font-size: 30px;
  color: white;
  padding: var(--bs-modal-padding);
}

.dependsWarning {
  font-size: 30px;
  color: white;
}